::-webkit-scrollbar {width: 0 !important;}

.scroll::-webkit-scrollbar { width: 6px !important;height: 1px;}
.scroll::-webkit-scrollbar-thumb { border-radius: 6px; box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); background: #535353;}
.scroll::-webkit-scrollbar-track { box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4); border-radius: 6px; background: #ededed;}

.scroll-light::-webkit-scrollbar { width: 4px !important;height: 1px;}
.scroll-light::-webkit-scrollbar-thumb { border-radius: 4px; box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2); background: #535353;}
.scroll-light::-webkit-scrollbar-track { box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2); border-radius: 4px; background: #ededed;}

b{font-weight:normal !important;}
.pointer{cursor:pointer;}
.underline{color:#626569 !important;text-decoration:underline;}
.pd10{padding-bottom:10px;}
.height100{height:100% !important;}
.scroll-y{overflow-y:scroll !important;}
.mr4{margin-right:4px;}
.mr8{margin-right:6px;}
.fs12{font-size:12px !important;}
.fs14{font-size:14px !important;}
.fs16{font-size:16px !important;}
.red{color:#dc3545 !important;}
.grey{color:#697e9f !important;}
.align-left{text-align:left;}
.align-right{text-align:right;}
.relative{position:relative !important;}
.tab-content{height:100%;}

.ant-spin-text{margin-top:16px;color:#616161;}
.ant-message{z-index:99999999 !important;}
.ant-popover-arrow-content{display:none !important;}
.ant-popover-inner-content{padding: 0 !important;}
.ant-statistic-content-value span{font-size:36px !important;color:#21aa93;font-weight:bold;}
.ant-picker-dropdown{z-index:99999999;}
.ant-tooltip-arrow-content{display:none !important;}

.nav-badge{position:absolute;display:block;width:6px;height:6px;border-radius:3px;right:10px;top:10px;background:#dc3545}

.addnew-user-sidebar{z-index:1000;}

.channel-order{text-decoration:none;}
.chat-list li .unread{background:#dc3545;position:absolute;left:10px;top:10px;z-index:1;width:16px;height:16px;line-height:16px;text-align:center;color:#ffffff;font-size:12px;}

.emoji{width:28px !important;height:28px !important;margin-top:-5px;}


.logo-img{width:120px;}

/*=manager-setting start=*/
.list-group-item:hover{background:#dee2e6 !important;}
/*=manager-setting end=*/
