.main_box{height:auto !important;overflow-y:scroll !important;}
.dashboard{display:flex;justify-content:space-between;align-items:flex-start;flex-wrap:wrap;padding-top:3rem;}
.board_card{border:1px solid #dee2e6;box-sizing:border-box;width:100%;margin-bottom:24px;}
@media (min-width: 768px){
    .board_card{width:49% !important;}
}
.board_card h2{padding:16px;font-size:20px;border-bottom:1px solid #dee2e6;}
.board_card section{padding:16px;}
.chat_status_total{list-style:none;padding:0;margin:0;display:flex;justify-content:space-around;align-items:center;height:75px;}
.chat_status_total a{display:flex;flex-direction:column;align-items:center;cursor:pointer;color:#626569;}
.total_icon{font-size:36px !important;color:#21aa93;}
.total_num{font-size:14px;}
.active_total{list-style:none;padding:0;margin:0;display:flex;justify-content:space-around;align-items:center;height:75px;}
.active_total li{display:flex;flex-direction:column;align-items:center;}
.active_total span{font-size:14px;}
.e_graph{height:300px;}

.reset{width:100%;min-height:768px;display:flex;flex-direction:column;justify-content:center;align-items:center;}
.reset img{width:200px;height:200px;}