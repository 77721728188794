.product_title {font-size:15px;margin-bottom:12px;}
.product {display: flex;justify-content: space-between;align-items: flex-start;flex-wrap: nowrap;width: 100%;overflow: hidden;text-decoration: none;color:#626569;} 
.product img {width: 80px;height: 80px;margin-right: 8px;}
.product_info {display: flex;width: 184px;flex-direction: column;justify-content: space-between;align-items: flex-start;overflow: hidden;}
.product_info h4 {width: 100%;font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 6px;}
.product_opt {width: 100%;font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 12px;}
.product_info section {width: 100%;display: flex;justify-content: space-between;align-items: baseline;}
.product_price {display: flex;justify-content: flex-start;align-items: baseline;}
.product_price strong { font-size: 20px;}
.product_price em { font-size: 12px; }
.product_info span { font-size: 14px; }

.overview h3 { margin-bottom: 12px; }
.overview_list {display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap;}
.overview_list a {width: 40%;display: flex;justify-content: space-between;align-items: baseline;margin-bottom: 8px;text-decoration: none;}
.overview_list a:nth-child(even) { margin-left: 15%; }
.overview_list strong {font-size: 18px;text-decoration: underline; }