.tab{height:100%;overflow-y:scroll;}
.tab_head{position:sticky;top:0;left:0;background:#f3f2ef;z-index:999;}

.msg_lead{color:#95aac9;}
.message_content{background:#ffffff;border-radius:8px;}

.waiting{display:flex;flex-direction:column;justify-content:center;align-items:center;padding-top:200px;}
.waiting span{color:#95aac9;}

.search_icon{position:absolute;top:9px;left:10px;z-index:99;font-size:18px;}
.type_show{display:block !important;transform:translate3d(-135px, 24px, 0px);top:0px !important;}

.chat_status{border:none;display:flex;flex-direction:column;align-items:center;cursor:pointer;color:#626569}
.chat_status.active{color:#21aa93;}
.chat_status_icon{font-size:24px !important;}

.channels{height:100%;overflow-y:scroll;}
.channel_head{position:sticky;top:0;left:0;z-index:999;background:#f3f2ef;}

.channel_active{border-color:#21aa93 !important;}
.card_list:hover{border-color:#21aa93 !important;}

.headerimg{width:30px;height:30px;border-radius:15px;}

.msg_list_wrap{position:relative;}
.loading_wrap{position:sticky;top:0;width:100%;text-align:center;}

.attachment_img{max-width:160px;}

.file_type{width:36px !important;height:36px !important;}
.read_check{margin-left: 4px;}
.has_read{color:#b70b1b;}
.unread{color:#d6d6d6;}

.emojis{display:flex;justify-content:flex-start;align-items:center;max-width:300px;flex-wrap:wrap;}
.emojis b{border:1px solid #f3f2ef;display:block;width:30px;height:30px;box-sizing:border-box;}
.emojis img{width:100%;height:100%;}

.words{display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;max-width:300px;max-height:200px;overflow-y:scroll;}
.words b{padding:4px 0;word-break:break-all;border-bottom:1px solid #f3f2ef;font-size:14px;display:block;width:100%;}

.write {position:relative;height: 130px;padding-left: 16px;background-color: #f3f2ef;border-radius: 5px;display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;}
.chat_tool{width: 100%;display: flex;justify-content: space-between;align-items: center;padding-top: 10px;}
.chat_tool_l{display: flex;justify-content: flex-start;align-items: center;}
.chat_tool_l i{margin-right:15px;}
.write_box{flex: 1;font-size: 14px;width: 100%;box-sizing: border-box;padding: 6px;border: 0;outline: none;background-color: #f3f2ef;font-family: "Open Sans",sans-serif;font-weight: 400;line-height: 24px;}
.write_link {cursor: pointer;position: absolute;right:14px;bottom: 8px;}

.drag_upload_top{display:block;width:100%;height:40px;position:absolute;left:0;top:-40px;}

.product_info {display: flex;width: 184px;flex-direction: column;justify-content: space-between;align-items: flex-start;overflow: hidden;}
.product_info h4 {width: 100%;font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 6px;}
.product_info section {width: 100%;display: flex;justify-content: space-between;align-items: baseline;}
.product_info span { font-size: 14px; }
.product_price {display: flex;justify-content: flex-start;align-items: baseline;}
.product_price strong { font-size: 20px;}
.product_price em { font-size: 12px; }

.overview h3 { margin-bottom: 12px; }
.overview_list {display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap;}
.overview_list a {width: 40%;display: flex;justify-content: space-between;align-items: baseline;margin-bottom: 8px;text-decoration: none;}
.overview_list a:nth-child(even) { margin-left: 15%; }
.overview_list strong {font-size: 18px;text-decoration: underline; }
/*引用消息*/
.yy{
    display: flex;
    flex-direction: column;
    border-left: solid 4px #898686;
    background: #dbdbdb8f;
    padding: 20px;
    margin-right: 50px;
    margin-bottom: 8px;
}
.yy_name{
    color: #616464;
    font-weight: 600;
    margin-bottom: 5px;
}
.yy_div, .yydis_div{
    margin-bottom: 10px;
}
.yydis_context{
    color: #787888;
    word-break: break-all;
}
.input_box_all {
    width: 100%;
    background-color: #eceff1;
    overflow-y: scroll;
}
.yy_des{
    display: flex;
    align-items: center;
}
.yy_des em {
    font-size: 13px;
    word-break: break-all;
    line-height: 16px;
}
.yy_des img{
    width: 40px;
    height: 40px;
    margin-right: 8px;
}
.yyutis{
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    color: #1a1a1a;
}
.yyutis img {
    width: 80px;
    height: 80px;
    margin-right: 8px;
}
.yydis{
    display: flex;
    flex-direction: column;
    border-left: solid 4px #cccccc;
    padding: 20px;
    margin-bottom: 10px;
    /*user-select: none;*/
    cursor: pointer;
    color: #787888c9;
    font-size: 15px;
}
.basic_msg{
    display: block;
    line-height: 21px;
    word-break: break-all;
}
.mask_layer{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #8979796b;
    z-index: 999;
    right: 0;
    bottom: 0;
    border-radius: 10px;
}
.goToQuoteButton {
    /*display: none;*/
    position: absolute;
    bottom: 195px;
    right: 88px;
    padding: 12px;
    background-color: #ffffff;
    color: #437fc2;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.goToQuoteButton img {
    margin-right: 3px;
}

.goToQuoteButton:hover {
    background-color: #ededed; /* 鼠标悬停时的背景色，根据需要调整 */
}