@font-face {
  font-family: "iconfont"; /* Project id 3817399 */
  src: url('iconfont.woff2?t=1676962808343') format('woff2'),
       url('iconfont.woff?t=1676962808343') format('woff'),
       url('iconfont.ttf?t=1676962808343') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tiwenquestion:before {
  content: "\e7cd";
}

.icon-repeat-line:before {
  content: "\e6c0";
}

.icon-xiaoxi:before {
  content: "\e620";
}

.icon-user-list:before {
  content: "\e60a";
}

.icon-bianji1:before {
  content: "\e609";
}

.icon-caidan:before {
  content: "\e61e";
}

.icon-bianji:before {
  content: "\e6b0";
}

.icon-wenda:before {
  content: "\e893";
}

.icon-quanbu:before {
  content: "\e643";
}

.icon-right-1-copy:before {
  content: "\e61f";
}

.icon-jian:before {
  content: "\e601";
}

.icon-jia:before {
  content: "\e602";
}

.icon-guanbi:before {
  content: "\e64d";
}

.icon-add-user:before {
  content: "\e605";
}

.icon-lianjiewenjian:before {
  content: "\e7dc";
}

.icon-lishishuju:before {
  content: "\e61c";
}

.icon-fasong_fill:before {
  content: "\e60d";
}

.icon-shanchu:before {
  content: "\e611";
}

.icon-robot:before {
  content: "\e600";
}

.icon-dengdai:before {
  content: "\e665";
}

.icon-ETL-jieshu:before {
  content: "\e642";
}

.icon-duihua1:before {
  content: "\e72a";
}

.icon-checkgou:before {
  content: "\e6f2";
}

.icon-menu_flgl:before {
  content: "\e65a";
}

.icon-changyong_wenjian:before {
  content: "\e6b5";
}

.icon-sousuotianchong:before {
  content: "\e694";
}

.icon-checkall:before {
  content: "\e718";
}

.icon-biaoqing:before {
  content: "\e644";
}

.icon-ai250:before {
  content: "\e6ee";
}

.icon-tubiao_daohangcaidan:before {
  content: "\e610";
}

.icon-jietu:before {
  content: "\e624";
}

.icon-youcecaidan:before {
  content: "\e606";
}

.icon-shezhi:before {
  content: "\e8b8";
}

.icon-touxiang:before {
  content: "\e673";
}

.icon-yueliang:before {
  content: "\e6b8";
}

.icon-duihua:before {
  content: "\e626";
}

