.tab{height:100%;overflow-y:scroll;}
.tab_head{position:sticky;top:0;left:0;z-index:999;background:#ffffff;}

.leaving{padding-right:8px;}
.leaving h2{margin-top:24px;font-size:18px;color:#555555;margin-bottom:12px;}
.leaving h2:nth-of-type(1){margin-top:0;}
.list{list-style:none;padding:0;}
.card{border:1px solid #ced4da;padding:16px 10px 10px 10px;border-radius:6px;margin-bottom:10px;}
.list_item{display:flex;justify-content:space-between;align-items:center;flex-wrap:wrap;}
.list_item section{width:50%;display:flex;justify-content:flex-start;margin-bottom:12px;}
.list_item strong{margin-right:6px;}
.budget_btn{display:flex;justify-content:center;margin-top:12px;}
.budget_btn button{border:1px solid #ced4da;padding:4px 8px;border-radius:3px;}
.budget_btn strong{font-weight:normal;}

.list_btn{font-style:normal;text-decoration:underline;cursor:pointer;}

.items{list-style:none;padding:0;}
.items li{display:flex;justify-content:flex-start;align-items:flex-start;border-bottom:1px solid #ced4da;padding-bottom:8px;margin-bottom:10px;position:relative;}
.items img{width:60px;height:60px;margin-right:8px;}
.item_info{width:100%;overflow:hidden;}
.item_info h3{font-size:16px;font-weight:normal;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.item_state em{font-size:13px;font-style:normal;margin-right:8px;font-weight:bold;}
.item_option{display:flex;justify-content:space-between;align-items:flex-end;}
.item_option section{display:flex;justify-content:flex-start;align-items:center;}
.item_option strong{margin-right:6px;}
.item_option i{font-style:normal;}
.item_option p{font-size:14px;font-weight:bold;margin:0 !important;}

.over_btn{font-size:12px;background:#21aa93;color:#ffffff;border:none;font-style:normal;padding:0 6px;border-radius:3px;position:absolute;left:0;bottom:8px;cursor:pointer;}

.memo textarea{border:1px solid #ced4da;width:100%;height:120px;padding:8px;box-sizing:border-box;resize:none;border-radius:3px;}
.memo_list{list-style:none;padding:0;}
.memo_list li{margin-bottom:12px;}
.memo_list section{display:flex;justify-content:space-between;align-items:center;margin-bottom:4px;}
.memo_list strong{font-size:14px;}
.memo_list span{font-size:12px;color:#8c98a5;}
.memo_btn{display:flex;justify-content:center;align-items:center;padding-top:12px;}
.memo_btn button{border:1px solid #ced4da;padding:4px 8px;border-radius:3px;margin:0 4px;}

.waiting{display:flex;flex-direction:column;justify-content:center;align-items:center;padding-top:200px;}
.waiting span{color:#95aac9;}